function showError(element) {
    element.style.display = 'block';
    element.style.color = 'red';
}

function getFolleto() {
    const url = document.getElementById('folletoButton').getAttribute('action');
    $.spinner().start();
    $.ajax({
        url: url,
        method: 'GET',
        success: (response) => {
            if (response.redirectUrl) {
                location.href = response.redirectUrl;
            } else {
                const errorFolletoElement = document.getElementById('errorFolletoMsg');
                errorFolletoElement.innerHTML = response.errorMsg;
                showError(errorFolletoElement);
            }
        },
        complete: () => $.spinner().stop()
    });
}

document.addEventListener('DOMContentLoaded', () => {
    document.getElementById('folletoButton').addEventListener('click', () => {
        const errorFolletoElement = document.getElementById('errorFolletoMsg');
        errorFolletoElement.style.display = 'none';
        getFolleto();
    });
});
